import React, { FC, ReactElement, useState, useEffect } from "react";
import Helmet from "react-helmet";
import * as t from "io-ts";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { Option, none, some, map, getOrElse, isSome } from "fp-ts/lib/Option";
import "typeface-spacegrotesk";
import style from "./index.module.css";
import { isRight } from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";

const MediaEntry = t.type({
  id: t.string,
  media_url: t.string,
  permalink: t.string,
  caption: optionFromNullable(t.string)
});
type MediaEntry = t.TypeOf<typeof MediaEntry>;

const MediaEntries = t.type({
  data: t.array(MediaEntry)
});
type MediaEntries = t.TypeOf<typeof MediaEntries>;

const MediaEntriesView: FC<MediaEntries> = ({ data }): ReactElement[] => {
  return data.map(({ permalink, id, media_url, caption }) => (
    <div className={style.media} key={id}>
      <a href={permalink} target="_blank">
        <img src={media_url} width="395" height="395" />
      </a>
      {isSome(caption) && <div className={style.caption}>{caption.value}</div>}
    </div>
  ));
};

const Home: FC = (): ReactElement => {
  const [entries, setEntries] = useState<Option<MediaEntries>>(none);
  useEffect((): void => {
    fetch("/data/instagram.json")
      .then(r => r.json())
      .then(json => MediaEntries.decode(json))
      .then(results => {
        if (isRight(results)) {
          setEntries(some(results.right));
        }
      });
  }, []);
  const entriesView = pipe(
    entries,
    map(entries => <MediaEntriesView data={entries.data.slice(0, 10)} />),
    getOrElse(() => <div />)
  );
  return (
    <div>
      <Helmet>
        <title>Everybody Loves Maude</title>
      </Helmet>
      <main>
        <div className={style.introduction}>
          <h1>Everybody Loves Maude</h1>
          <p>
            Maude is a 9 year old Domestic Shorthair with both the biggest
            appetite &amp; the biggest heart in the world! She was rescued as a
            23 pound sweetheart and, through diet and exercise is thriving
            today!
          </p>
          <p>
            <a
              href="https://www.instagram.com/everybodylovesmaude/"
              target="_blank"
            >
              See Maude on Instagram
            </a>{" "}
            or check out{" "}
            <a href="https://www.citydogsrescuedc.org/" target="_blank">
              City Dogs Rescue &amp; City Kitties
            </a>
            .
          </p>
        </div>
        <div className={style.instagram}>{entriesView}</div>
      </main>
    </div>
  );
};

export default Home;
